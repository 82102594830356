// import { useEffect, useCallback, useState } from 'react'
import { useEffect, useCallback } from 'react'
// import { message, Row, Col, Card, Table, Grid, Button, Statistic } from 'antd'
import { message, Statistic } from 'antd'
import { getDashboardInfo, selectGetDashboardInfoLoading, selectGetDashboardInfoData } from './reducers'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { camelCaseToTitleCase, getCurrencyName, getCurrencyValue } from '../../utils/utils'
import { CardContainer, PageHeaderContainer, CardWrapper } from './styles'
import { selectGlobalCurrency } from '../AuthLayout/reducers'
// import { Area, Column } from '@ant-design/charts'
// import ColumnChartConfig from './chartconfigs/ColumnChartConfig'
// import AreaChartConfig from './chartconfigs/AreaChartConfig'

// const { useBreakpoint } = Grid

const DashboardPage = (): JSX.Element => {
  const dispatch = useAppDispatch()
  // const screens = useBreakpoint()
  const getDashboardInfoLoading = useAppSelector(selectGetDashboardInfoLoading)
  const getDashboardInfoData = useAppSelector(selectGetDashboardInfoData)
  const globalCurrency = useAppSelector(selectGlobalCurrency)
  // const [key, setKey] = useState('settlements')

  // const tabList = [
  //   {
  //     key: 'settlements',
  //     tab: 'SETTLEMENTS'
  //   },
  //   {
  //     key: 'topup',
  //     tab: 'TOPUP'
  //   },
  //   {
  //     key: 'login',
  //     tab: 'LOGIN'
  //   }
  // ]

  // const columns = [
  //   {
  //     dataIndex: 'amount'
  //   },
  //   {
  //     dataIndex: 'text'
  //   },
  //   {
  //     dataIndex: 'activity'
  //   },
  //   {
  //     dataIndex: 'status'
  //   }
  // ]

  // const tableData = [
  //   {
  //     key: 1,
  //     amount: 131434,
  //     text: 'wadassdfg2ad',
  //     activity: 'a min ago',
  //     status: 'Failed'
  //   },
  //   {
  //     key: 2,
  //     amount: 131434,
  //     text: 'dfgw131daasd',
  //     activity: '2 min ago',
  //     status: 'Completed'
  //   },
  //   {
  //     key: 3,
  //     amount: 131434,
  //     text: 'kjhdfg423sd',
  //     activity: '3 min ago',
  //     status: 'Completed'
  //   },
  //   {
  //     key: 4,
  //     amount: 131434,
  //     text: 'cxvbxas312',
  //     activity: '4 min ago',
  //     status: 'Failed'
  //   }
  // ]

  // const contentList: {
  //   [key: string]: JSX.Element
  // } = {
  //   settlements:
  // <Table
  //   size='small'
  //   rowKey='key'
  //   scroll={{ x: screens.xl === true ? 0 : 500 }}
  //   dataSource={tableData} columns={columns}
  //   showHeader={false} pagination={false}
  //   footer={() => <span style={{ textAlign: 'right', display: 'block' }}> <Button type='link'>View All Payments</Button></span>}
  // />,
  //   topup:
  // <Table
  //   size='small'
  //   rowKey='key'
  //   scroll={{ x: screens.xl === true ? 0 : 500 }}
  //   dataSource={tableData} columns={columns}
  //   showHeader={false} pagination={false}
  //   footer={() => <span style={{ textAlign: 'right', display: 'block' }}> <Button type='link'>View All Payments</Button></span>}
  // />,
  //   login:
  // <Table
  //   size='small'
  //   rowKey='key'
  //   scroll={{ x: screens.xl === true ? 0 : 500 }}
  //   dataSource={tableData} columns={columns}
  //   showHeader={false} pagination={false}
  //   footer={() => <span style={{ textAlign: 'right', display: 'block' }}> <Button type='link'>View All Payments</Button></span>}
  // />
  // }

  const getDashboardInfoAction = useCallback(
    async (): Promise<any> => {
      try {
        await dispatch(getDashboardInfo({ currencyId: getCurrencyValue(globalCurrency) })).then(unwrapResult)
      } catch (error: any) {
        return await message.error(error.message)
      }
    },
    [dispatch, globalCurrency]
  )

  useEffect(() => {
    if (globalCurrency !== '') {
      getDashboardInfoAction().finally(() => {})
    }
  }, [getDashboardInfoAction, globalCurrency])

  // function onTabChange (key: string): void {
  //   setKey(key)
  // }

  return (
    <>
      <PageHeaderContainer
        ghost={false}
      >
        <CardWrapper>
          {
            Object.entries(getDashboardInfoData).map(([key, value], i) => {
              if (key === 'balance') {
                return (
                  <CardContainer key={key} indexnumber={i} loading={getDashboardInfoLoading} bordered={false} bodyStyle={{ padding: 0 }}>
                    <Statistic
                      title={<span style={{ color: 'white' }}>Balance</span>}
                      value={getDashboardInfoData.balance.balance}
                      valueStyle={{ color: 'white' }}
                      precision={2}
                      prefix={getCurrencyName(getDashboardInfoData.balance.currency)}
                    />
                    <div>
                      <small>Settled: {getCurrencyName(getDashboardInfoData.balance.currency)} {getDashboardInfoData.balance.settledBalance}</small>
                    </div>
                    <div>
                      <small>Unsettled: {getCurrencyName(getDashboardInfoData.balance.currency)} {getDashboardInfoData.balance.unsettleBalance}</small>
                    </div>
                  </CardContainer>
                )
              }

              return (
                <CardContainer key={key} bgcolor='white' indexnumber={i} loading={getDashboardInfoLoading} bordered={false} bodyStyle={{ padding: 0 }}>
                  <Statistic
                    title={camelCaseToTitleCase(key)}
                    value={value.amount}
                    precision={0}
                    prefix={getCurrencyName(getDashboardInfoData.balance.currency)}
                  />
                  <div>
                    <small className='invert'>Total: {value.total}</small>
                  </div>
                  <div className='footer'>
                    <small className='invert'>Service Fee: {getCurrencyName(getDashboardInfoData.balance.currency)} {value.serviceFee}</small>
                  </div>
                </CardContainer>
              )
            })
          }
        </CardWrapper>
      </PageHeaderContainer>
      {/* <Row gutter={[10, 10]}>
        <Col xs={24}>
          <Card title='Today number of transactions'>
            <Area {...AreaChartConfig} />
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card
            title='Recent Activity'
            tabList={tabList}
            activeTabKey={key}
            onTabChange={key => {
              onTabChange(key)
            }}
          >
            {
              contentList[key]
            }
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card>
            <div style={{ height: '306px' }}>
              <Column {...ColumnChartConfig} />
            </div>
          </Card>
        </Col>
      </Row> */}
    </>
  )
}

export default DashboardPage
